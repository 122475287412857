import React, { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";

import Swal from "sweetalert2";
import "./styles.scss";
import { Row, Col, Form, FormGroup, Label, Input, Button } from "reactstrap";

import Page from "components/Page";
import api from "config/api";
import errorRequest from "common/errorRequest";

export default function Settings() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [nuvemStoreId, setNuvemStoreId] = useState(0);
  const [accept_cpf, setAcceptCPF] = useState(() => {
    return localStorage.getItem("acceptCpf");
  });

  useEffect(() => {
    fetchUser();
  }, []);

  async function fetchUser() {
    setLoading(true);
    try {
      const { data } = await api.get(`/user`);

      setNuvemStoreId(data.data.nuvemshop_store_id);

      if (!accept_cpf) {
        const response = await api.get(
          `/setting/${data.data.nuvemshop_store_id}`
        );

        setAcceptCPF(response.data.data.accept_cpf);

        localStorage.setItem("acceptCpf", response.data.data.accept_cpf);
      }

      if (accept_cpf === "1") {
        document.getElementById("accept-cpf-true").checked = true;
      } else {
        document.getElementById("accept-cpf-false").checked = true;
      }
    } catch (error) {
      errorRequest(history, error);
    }
    setLoading(false);
  }

  async function saveSettings() {
    Swal.fire({
      title: "Atenção",
      text: "Deseja Realmente salvar?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim!",
    }).then(async (result) => {
      if (result.value) {
        setLoading(true);

        try {
          const { data } = await api.put(`/setting`, {
            nuvemshop_store_id: nuvemStoreId,
            published: 1,
            accept_cpf,
          });

          Swal.fire(
            "Salvo!",
            "As configurações foram salvas com sucesso",
            "success"
          );

          localStorage.setItem("acceptCpf", data.data.accept_cpf);
        } catch (error) {
          errorRequest(history, error);
        }
        setLoading(false);
      }
    });
  }

  return (
    <Page back loading={loading}>
      <Form>
        <Row>
          <Col className="col-4">
            <FormGroup>
              <Label className="form-label" for="accept-cpf-signup">
                Aceitar pessoa física?
              </Label>
              <FormGroup className="d-flex kit-radioform " tag="fieldset">
                <FormGroup check>
                  <Label check>
                    <Input
                      type="radio"
                      name="accept-cpf-signup"
                      id="accept-cpf-true"
                      onClick={() => setAcceptCPF(1)}
                    />
                    Sim
                  </Label>
                </FormGroup>
                <FormGroup className="ml-3" check>
                  <Label check>
                    <Input
                      type="radio"
                      name="accept-cpf-signup"
                      id="accept-cpf-false"
                      onClick={() => setAcceptCPF(0)}
                    />
                    Não
                  </Label>
                </FormGroup>
              </FormGroup>
            </FormGroup>
          </Col>
        </Row>
        <Button
          className="float-right mt-3"
          color="primary"
          onClick={(e) => saveSettings(e)}
        >
          Salvar
        </Button>
      </Form>
    </Page>
  );
}
