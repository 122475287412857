import React, { useState, useEffect } from "react";

import Swal from "sweetalert2";

import { useHistory } from "react-router-dom";

import "./styles.scss";
import {
  Row,
  Col,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  Button,
  Input,
  Table,
} from "reactstrap";

import api from "config/api";
import Page from "components/Page";
import errorRequest from "common/errorRequest";
import CustomPagination from "components/CustomPagination";
import maskCpfCnpj from "common/maskCpfCnpj";

export default function Customers() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(4);
  const [customers, setCustomers] = useState([]);
  const [search, setSearch] = useState("");
  const [total, setTotal] = useState();
  const [page, setPage] = useState(1);
  const limit = 10;

  useEffect(() => {
    fetchCustomers();
  }, [page, status]);

  async function fetchCustomers() {
    setLoading(true);
    if (status === 4) {
      try {
        const { data } = await api.get(
          `/customers?limit=${limit}&offset=${page - 1}&search=${search}`
        );
        setCustomers(data.data);
        setTotal(data.pagination.total);
      } catch (error) {
        errorRequest(history, error);
      }
    } else {
      try {
        const { data } = await api.get(
          `/customer_status/${status}?limit=${limit}&offset=${
            page - 1
          }&search=${search}`
        );
        setCustomers(data.data);
        setTotal(data.pagination.total);
      } catch (error) {
        errorRequest(history, error);
      }
    }
    setLoading(false);
  }

  async function deleteCustomer(idCustomer) {
    Swal.fire({
      title: "Atenção",
      text: "Deseja realmente excluir esse cliente?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim!",
    }).then(async (result) => {
      if (result.value) {
        setLoading(true);
        try {
          await api.delete(`/customer/${idCustomer}`);
          Swal.fire("Pronto!", "O cliente foi excluido com sucesso", "success");
          await fetchCustomers();
        } catch (error) {
          errorRequest(history, error);
        }
        setLoading(false);
      }
    });
  }

  return (
    <Page loading={loading}>
      <>
        <Row className="mb-4">
          <Col md="4">
            <InputGroup>
              <Input
                className="table-search-input"
                placeholder="Buscar..."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onClick={() => fetchCustomers()}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    fetchCustomers();
                  }
                }}
              />
              <InputGroupAddon color="primary" addonType="append">
                <Button
                  className="table-search-button"
                  onClick={() => fetchCustomers()}
                >
                  <i className="fas fa-search" />
                </Button>
              </InputGroupAddon>
            </InputGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <Input
                type="select"
                onChange={(e) => setStatus(+e.target.value)}
                value={status}
              >
                <option key="order-status-4" value={4}>
                  Todos os pedidos
                </option>
                <option key="order-status-0" value={0}>
                  Aguardando análise
                </option>
                <option key="order-status-1" value={1}>
                  Aprovados
                </option>
                <option key="order-status-2" value={2}>
                  Reprovados
                </option>
              </Input>
            </FormGroup>
          </Col>
          <Col>
            <div
              className="table-action-refresh"
              onClick={() => fetchCustomers()}
            >
              <i className="fas fa-redo" />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Table
              className="d-none d-md-table sales-customers-table text-center"
              responsive
              striped
            >
              <thead>
                <tr>
                  <th className="TableTh">Razão Social</th>
                  <th className="TableTh">Nome</th>
                  <th className="TableTh">CPF/CNPJ</th>
                  <th className="TableTh">Situação</th>
                  <th className="TableTh">Ações</th>
                </tr>
              </thead>
              <tbody>
                {customers && customers.length === 0 ? (
                  <tr>
                    <td colSpan="6">Não foi encontrado nenhum registro...</td>
                  </tr>
                ) : (
                  customers.map((customer) => (
                    <tr key={customer.id}>
                      <td className="TableTd">{customer.razao_social}</td>
                      <td className="TableTd">{customer.name}</td>
                      <td className="TableTd table-doc">
                        {maskCpfCnpj(customer.cnpj)}
                      </td>
                      <td className="TableTd">
                        <span
                          className={`customer-status customer-status-${customer.status}`}
                        >
                          {customer.status === 0
                            ? "Aguardando análise"
                            : customer.status === 1
                            ? "Aprovado"
                            : "Reprovado"}
                        </span>
                      </td>
                      <td className="TableTd">
                        <div className="table-action">
                          <div
                            className="table-action-button-edit"
                            onClick={() =>
                              history.push(`/admin/cliente?id=${customer.id}`)
                            }
                          >
                            <i className="fas fa-edit" />
                          </div>
                          <div
                            className="table-action-button-delete"
                            onClick={() => deleteCustomer(customer.id)}
                          >
                            <i className="fas fa-trash-alt" />
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
            {total > limit && (
              <div className="d-flex my-3 justify-content-center">
                <CustomPagination
                  total={total}
                  page={page}
                  length={customers.length}
                  limit={limit}
                  setPage={setPage}
                />
              </div>
            )}
          </Col>
        </Row>
      </>
    </Page>
  );
}
