import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import Swal from "sweetalert2";
import axios from "axios";
import DatePicker from "components/DatePicker";

import { useHistory, useLocation } from "react-router-dom";

import "./styles.scss";

import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
} from "reactstrap";

import states from "common/statesOfBrazil";
import maskCpfCnpj from "common/maskCpfCnpj";
import validateCpfCnpj from "common/validateCpfCnpj";
import maskPhone from "common/maskPhone";
import Page from "components/Page";
import api from "config/api";
import errorRequest from "common/errorRequest";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function SingleCustomer() {
  const history = useHistory();
  const query = useQuery();
  const [loading, setLoading] = useState(false);
  const id = query.get("id");

  const [status, setStatus] = useState("");
  const [aprovedStatus, setAprovedStatus] = useState(false);

  const [name, setName] = useState("");
  const [razaoSocial, setRazaoSocial] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [dataNascimento, setDataNascimento] = useState("");
  const [inscricaoEstadual, setInscricaoEstadual] = useState("");

  const [cep, setCep] = useState("");
  const [rua, setRua] = useState("");
  const [numero, setNumero] = useState("");
  const [complemento, setComplemento] = useState("");
  const [bairro, setBairro] = useState("");
  const [cidade, setCidade] = useState("");
  const [estado, setEstado] = useState("");

  async function buscaCep() {
    setLoading(true);
    await axios
      .get(
        `https://viacep.com.br/ws/${cep
          .replace(".", "")
          .replace("-", "")}/json/`
      )
      .then((res) => {
        setBairro(res.data.bairro);
        setCidade(res.data.localidade);
        setEstado(res.data.uf);
        setRua(res.data.logradouro);
        return;
      })
      .catch(() => {
        Swal.fire("Erro", "Verifique o cep e tente novamente!", "error");
      });
    setLoading(false);
  }

  useEffect(() => {
    if (id) fetchCustomer();
  }, []);

  async function fetchCustomer() {
    setLoading(true);
    try {
      const { data } = await api.get(`/customer/${id}`);
      setStatus(data.data.status);
      data.data.status === 1 ? setAprovedStatus(true) : setAprovedStatus(false);
      setName(data.data.name);
      setRazaoSocial(data.data.razao_social);
      setCnpj(data.data.cnpj);
      setPhone(data.data.phone);
      setEmail(data.data.email);
      setDataNascimento(new Date(data.data.data_nascimento));
      setInscricaoEstadual(data.data.ie);
      setCep(data.data.cep);
      setRua(data.data.rua);
      setNumero(data.data.numero);
      setComplemento(data.data.complemento);
      setBairro(data.data.bairro);
      setCidade(data.data.cidade);
      setEstado(data.data.estado);
    } catch (error) {
      errorRequest(history, error);
    }
    setLoading(false);
  }

  async function saveCustomer(event) {
    event.preventDefault();
    if (checkBirthday(dataNascimento) >= 0) {
      if (aprovedStatus) {
        Swal.fire(
          "Atenção",
          "Para editar um usuário aprovado, use o painel da nuvem shop",
          "warning"
        );
        return;
      }

      if (
        !name ||
        !razaoSocial ||
        !cnpj ||
        !phone ||
        !email ||
        !dataNascimento ||
        !cep ||
        !rua ||
        !numero ||
        !bairro ||
        !cidade ||
        !estado
      ) {
        Swal.fire(
          "Atenção",
          "Preencha todos os campos e tente novamente!",
          "warning"
        );
        return;
      }

      if (!validateCpfCnpj(cnpj)) {
        Swal.fire(
          "Atenção",
          "CNPJ ou cnpj está inválido, verifique!",
          "warning"
        );
        return;
      }

      if (complemento && complemento.length > 30) {
        Swal.fire(
          "Atenção",
          "Complemento excedeu o tamanho permitido. Máximo de 30 carateres.",
          "warning"
        );
        return;
      }

      Swal.fire({
        title: "Atenção",
        text: "Deseja Realmente salvar?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      }).then(async (result) => {
        if (result.value) {
          setLoading(true);

          const data = {
            status,
            name,
            razao_social: razaoSocial,
            cnpj: cnpj.replace(/\D/g, ""),
            phone: phone.replace(/\D/g, ""),
            // email,
            data_nascimento: dataNascimento,
            ie: inscricaoEstadual,
            cep: cep.replace(/\D/g, ""),
            rua,
            numero,
            complemento,
            bairro,
            cidade,
            estado,
          };

          try {
            if (id) {
              await api.put(`/customer/${id}`, data);
              Swal.fire("Salvo!", "O cliente foi salvo com sucesso", "success");
              history.push("/admin/home");
            } else {
              Swal.fire("Erro!", "O cliente não existe", "error");
            }
          } catch (error) {
            errorRequest(history, error);
          }
          setLoading(false);
        }
      });
    } else {
      Swal.fire(
        "Erro",
        "Verifique a idade do cliente e tente novamente",
        "error"
      );
      return;
    }
  }

  function checkBirthday(nascimento) {
    let idade = new Date(nascimento);
    let hoje = new Date();
    let anoAtual = hoje.getFullYear();
    let ano = idade.getFullYear();
    return anoAtual - ano;
  }

  return (
    <Page loading={loading} back>
      <>
        <Form>
          <Row>
            <Col className="col-6">
              <FormGroup className="mb-1">
                <Label className="form-label" for="inputName">
                  Cliente
                </Label>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="far fa-user" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    className="form-input"
                    type="text"
                    placeholder="Nome"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup className="mb-1">
                <Label className="form-label" for="inputName">
                  Razão Social
                </Label>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fas fa-industry" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    className="form-input"
                    type="text"
                    placeholder="Razão Social"
                    value={razaoSocial}
                    onChange={(e) => setRazaoSocial(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <Row form>
                <Col md={6}>
                  <FormGroup className="mb-1">
                    <Label className="form-label" for="inputDoc">
                      CNPJ / CPF
                    </Label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="far fa-id-card" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <NumberFormat
                        className="form-input"
                        type="text"
                        id="inputDoc"
                        placeholder="cnpj/CNPJ"
                        format={maskCpfCnpj}
                        customInput={Input}
                        value={cnpj}
                        onChange={(e) => setCnpj(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <Label className="form-label" for="inputTel">
                    Telefone
                  </Label>
                  <InputGroup className="mb-1">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-phone" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <NumberFormat
                      className="form-input"
                      type="text"
                      id="inputTel"
                      placeholder="Telefone"
                      format={maskPhone}
                      customInput={Input}
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </InputGroup>
                </Col>
              </Row>
              <Row form>
                <Col md={6}>
                  <FormGroup className="mb-1">
                    <Label className="form-label" for="inputEmail">
                      Email
                    </Label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="far fa-envelope" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        disabled
                        className="form-input"
                        type="email"
                        placeholder="Email"
                        value={email}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup className="mb-1">
                    <Label className="form-label">Data de nascimento</Label>
                    <DatePicker
                      value={dataNascimento}
                      onChange={(e) => setDataNascimento(e._d)}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup className="mb-1">
                <Label className="form-label" for="inputName">
                  Inscrição Estadual
                </Label>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="far fa-file-alt" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    className="form-input"
                    type="text"
                    placeholder="Inscrição Estadual"
                    value={inscricaoEstadual}
                    onChange={(e) => setInscricaoEstadual(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <Row form>
                <Col md={6}>
                  <FormGroup className="mb-1">
                    <Label className="form-label" for="inputZipcode">
                      CEP
                    </Label>
                    <InputGroup className="mb-1">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-location-arrow" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <NumberFormat
                        className="form-input"
                        type="text"
                        placeholder="CEP"
                        value={cep}
                        onChange={(e) => setCep(e.target.value)}
                        customInput={Input}
                        format="##.###-###"
                        onKeyDown={(e) => {
                          if (e.keyCode === 13) {
                            buscaCep();
                          }
                        }}
                      />
                      <InputGroupAddon addonType="append">
                        <Button
                          className="table-search-button"
                          onClick={() => buscaCep()}
                        >
                          <i className="fas fa-search" />
                        </Button>
                      </InputGroupAddon>
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col md={8}>
                  <FormGroup className="mb-1">
                    <Label className="form-label" for="inputAddress">
                      Rua
                    </Label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-road" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        className="form-input"
                        type="text"
                        id="inputAddress"
                        placeholder="Rua"
                        value={rua}
                        onChange={(e) => setRua(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup className="mb-1">
                    <Label className="form-label" for="inputNumber">
                      Número
                    </Label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>₁₂₃</InputGroupText>
                      </InputGroupAddon>
                      <Input
                        className="form-input"
                        type="text"
                        id="inputNumber"
                        placeholder="Número"
                        value={numero}
                        onChange={(e) => setNumero(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col md={6}>
                  <FormGroup className="mb-1">
                    <Label className="form-label" for="inputComplement">
                      Complemento
                    </Label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="far fa-building" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        type="text"
                        id="inputComplement"
                        placeholder="Complemento"
                        value={complemento}
                        onChange={(e) => setComplemento(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup className="mb-1">
                    <Label className="form-label" for="inputDistrict">
                      Bairro
                    </Label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="far fa-map" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        className="form-input"
                        type="text"
                        id="inputDistrict"
                        placeholder="Bairro"
                        value={bairro}
                        onChange={(e) => setBairro(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col md={9}>
                  <FormGroup className="mb-1">
                    <Label className="form-label" for="inputCity">
                      Cidade
                    </Label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-city" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        className="form-input"
                        type="text"
                        id="inputCity"
                        placeholder="Cidade"
                        value={cidade}
                        onChange={(e) => setCidade(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup className="mb-1">
                    <Label className="form-label" for="inputState">
                      Estado
                    </Label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <span role="img" aria-label="icone estado">
                            🇸🇵
                          </span>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        id="inputState"
                        type="select"
                        value={estado}
                        onChange={(e) => setEstado(e.target.value)}
                      >
                        {states.map((state) => (
                          <option key={state.id} value={state.nome}>
                            {state.nome}
                          </option>
                        ))}
                      </Input>
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
            <Col className="col-6">
              <FormGroup className="mb-1">
                <Label className="form-label" for="inputStatus">
                  Status
                </Label>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fas fa-info-circle" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    id="inputStatus"
                    type="select"
                    value={status}
                    onChange={(e) => setStatus(+e.target.value)}
                  >
                    <option value={0}>Aguardando análise</option>
                    <option value={1}>Aprovado</option>
                    <option value={2}>Reprovado</option>
                  </Input>
                </InputGroup>
              </FormGroup>
              {/* <Row form>
                <Col md={6}>
                  <FormGroup className="mb-1">
                    <Label className="form-label" for="inputPassword">
                      Senha
                    </Label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-key" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        className="form-input"
                        type="password"
                        id="inputPassword"
                        placeholder="Senha"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup className="mb-1">
                    <Label className="form-label" for="inputConfirmPassword">
                      Confirme a Senha
                    </Label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-key" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        className="form-input"
                        type="password"
                        id="inputConfirmPassword"
                        placeholder="Senha"
                        value={passwordConfirm}
                        onChange={(e) => setPasswordConfirm(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row> */}
            </Col>
          </Row>
          <Button
            className="float-right mt-3"
            color="primary"
            onClick={(e) => saveCustomer(e)}
          >
            Salvar
          </Button>
        </Form>
      </>
    </Page>
  );
}
