import { cnpj, cpf } from 'cpf-cnpj-validator';

export default function validateCpfCnpj(value) {

  if (!value)
    return "";

  value = value.replace(/\D/g, "");

  return value.length <= 11 ? cpf.isValid(value) : cnpj.isValid(value);
}
