import Swal from "sweetalert2";

export default function errorRequest(history, error) {
  if (error.response && error.response.status === 401) {
    Swal.fire({
      title: "Token inválido",
      text: "Por favor, efetue login novamente",
      icon: "info",
      timer: 3000,
      timerProgressBar: true,
    }).then(() => {
      localStorage.clear();
      history.push("/");
    });
  } else {
    Swal.fire({
      title: "Erro",
      text:
        (error.response && error.response.data.message) ||
        "Ocorreu um erro, verifique os dados e tente novamente!",
      icon: "error",
      timer: 3000,
      timerProgressBar: true,
    });
  }
}
