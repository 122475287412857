/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  CardImg,
} from "reactstrap";

import Loading from 'components/Loading';
import api from 'config/api';

import "./styles.scss";
import logo from "../../../assets/img/logo_dark_crm.png";
import validatePassword from '../../../common/validatePassword';

export default function Login() {
  const history = useHistory();
  const [password, setPassword] = useState('');
  const [strong, setStrong] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState([]);
  const [loading, setLoading] = useState(false);

  // Verifica a 'força' da senha
  useEffect(() => {
    const { strong, error } = validatePassword(password);
    setStrong(strong);
    setPasswordError(error);
  }, [password]);

  async function submit(e) {
    e.preventDefault();
    let errors = [];

    if (passwordError) return;

    if (!confirmPassword) {
      errors.push('Confirme sua senha');
    } else {
      if (password !== confirmPassword) {
        errors.push('As senhas não coincidem');
      }
    }
    setError(errors);

    if (errors.length === 0) {
      setLoading(true);
      setError([]);
      try {
        await api.post('user', {
          password,
        });
        Swal.fire(
          'Cadastro realizado com sucesso!',
          'Agora acesse seu e-mail para confirmá-lo',
          'success'
        ).then(() => {
          history.push('/');
        });
      } catch (error) {
        Swal.fire('Erro', error.response.data.message, 'error');
      }
      setLoading(false);
    }
  }
  
  return (
    <div className="div-container">
      {loading && <Loading />}
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardHeader
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            className="bg-transparent p-5"
          >
            <CardImg src={logo} alt="logo" />
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>Escolha sua senha</small>
            </div>
            <Form role="form">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input 
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Senha" 
                  type="password" 
                  maxLength="24"
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input 
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  placeholder="Confirmar Senha" 
                  type="password" 
                  maxLength="24"
                  />
                </InputGroup>
              </FormGroup>
              <div className="text-muted font-italic">
                <small>
                  segurança da senha:{' '}
                  {!strong ? (
                    <span className="text-danger font-weight-700">fraca</span>
                  ) : (
                    <span className="text-success font-weight-700">forte</span>
                  )}
                </small>
                <br></br>
                {passwordError && (
                  <small className="error-info">{passwordError}</small>
                )}
                {error &&
                  error.map((err, i) => (
                    <small key={i} className="error-info">
                      {err}
                      <br></br>
                    </small>
                  ))}
              </div>
              <div className="text-center">
                <Button
                  onClick={(e) => submit(e)}
                  className="my-4"
                  color="primary"
                  type="button"
                >
                  Salvar
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </div>
  );
}
