import UserProfile from "views/admin/UserProfile";
import Customers from "views/admin/Customers/index.jsx";
import SingleCustomer from "views/admin/Customers/SingleCustomer/index.jsx";

import Settings from "views/admin/Settings/index.jsx";

import Support from "views/admin/Support/index.jsx";
import NotFound from "views/admin/NotFound";

var routes = [
  {
    path: "/user-profile",
    name: "Perfil do usuário",
    invisible: true,
    component: UserProfile,
    layout: "/admin",
  },
  // Manter a home sempre na primeira posição
  {
    path: "/home",
    name: "Sistema de atacado",
    icon: "fas fa-layer-group",
    component: Customers,
    layout: "/admin",
  },
  {
    path: "/cliente",
    name: "Gerenciamento de Cliente",
    invisible: true,
    component: SingleCustomer,
    layout: "/admin",
  },

  {
    path: "/configuracoes",
    name: "Configurações",
    icon: "fas fa-wrench",
    component: Settings,
    layout: "/admin",
  },

  // Manter o support e notfound sempre nas últimas posições respectivamente
  {
    path: "/suporte",
    name: "Suporte", //Nome dentro do componente
    icon: "far fa-question-circle", //Ícone da sidebar
    component: Support,
    layout: "/admin",
  },
  {
    path: "*",
    invisible: true,
    component: NotFound,
    layout: "/admin",
  },
];
export default routes;
